@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~design-system-old/build/css/design-system-old.css";
@import "~design-system/build/css/design-system.css";
@import "theme/colors.css";
@import "theme/defaultTheme.css";
@import "theme/wds.css";
@import "assets/fonts/custom/index.css";
@import "assets/fonts/google/index.css";

:root {
  /* TODO: This needs to be fixed! This override is to maintain branding changes. */
  --ads-color-background-secondary: var(--ads-v2-color-bg-subtle);
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
  color: var(--ads-v2-color-fg);
}

body.dragging * {
  cursor: grabbing !important;
}

/**
  Disable outline completely from all components
*/
:focus,
:focus-visible {
  outline: none !important;
  outline-offset: 0 !important;
}

/**
  Disable outline for adsv2 select component
*/
.ads-v2-select.rc-select-focused {
  outline: none !important;
  outline-offset: 0 !important;
}

#root.overlay {
  opacity: 0;
  pointer-events: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}

span.bp3-popover-target {
  display: flex;
  width: 100%;
}

span.bp3-popover-target > * {
  flex-grow: 1;
}

.bp3-popover,
.bp3-popover2 {
  box-shadow: var(--ads-v2-shadow-popovers) !important;
}

.bp3-popover2-content {
  border-radius: var(--ads-v2-border-radius) !important;
}

.action-selector-popup {
  background-color: var(--ads-v2-color-bg);
  border-radius: var(--ads-v2-border-radius) !important;
  border: 1px solid var(--ads-v2-color-border-muted) !important;
}

.action-selector-popup span.bp3-popover-target > * {
  max-width: 100%;
}

add-action.disabled span {
  cursor: not-allowed;
}

.action-selector-popup .bp3-button-text {
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

/**
  action selector block styles
**/
.bp3-submenu.bp3-popover > .bp3-popover-content {
  box-shadow: none;
}

.action-card-border {
  border-color: var(--ads-v2-color-border);
}

.action-card-next-selected {
  border-bottom: 1px solid var(--ads-v2-color-border-emphasis-plus);
}

.action-card-border-selected {
  border-color: var(--ads-v2-color-border-emphasis-plus) !important;
}

.action-card-last-block {
  border-radius: 0px 0px var(--ads-v2-border-radius) var(--ads-v2-border-radius);
}

.main-block-radius {
  border-radius: var(--ads-v2-border-radius);
}

.main-block-radius-selected {
  border-radius: var(--ads-v2-border-radius) var(--ads-v2-border-radius) 0px 0px;
}

.bp3-popover.bp3-minimal.bp3-submenu {
  padding-right: 0px;
}

div.bp3-popover-arrow {
  display: none;
}

.bp3-button.bp3-loading {
  cursor: default !important;
}

.display-none {
  display: none;
}

.t--editor-appname-menu-portal {
  z-index: 9 !important;
}

.bp3-popover .bp3-input {
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--ads-v2-color-border);
  border-radius: var(--ads-v2-border-radius) !important;
}

.bp3-popover .bp3-input:focus {
  border-color: var(--ads-v2-color-border-emphasis);
  outline: 0;
}

.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.bp3-popover.bp3-minimal.action-selector-dropdown {
  margin-top: 0px !important;
}

.layout-control.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.bp3-popover.none-shadow-popover {
  box-shadow: none;
  border: 0px;
}

.bp3-datepicker {
  border-radius: 0 !important;
}

/* making both the Modal layer and the Dropdown layer */
.bp3-modal-widget,
.appsmith_widget_0 > .bp3-portal {
  z-index: 2 !important;
}

/* Portals on the Modal widget */
.bp3-modal-widget .bp3-portal {
  z-index: 21 !important;
}

.bp3-popover2-content {
  background-color: var(--ads-v2-color-bg) !important;
  border-radius: var(--ads-v2-border-radius) !important;
}
.bp3-overlay-backdrop {
  background-color: rgba(16, 22, 26, 0.7) !important;
}

.bp3-overlay-zindex {
  z-index: 20 !important;
}

/** color picker input control */
.color-picker-input,
.color-picker-input > .bp3-popover-content {
  border-radius: var(--ads-v2-border-radius) !important;
}

.color-picker-input {
  box-shadow: var(--ads-v2-shadow-popovers) !important;
}

.icon-select-popover,
.icon-select-popover > .bp3-popover-content {
  border-radius: var(--ads-v2-border-radius) !important;
}

.icon-select-popover {
  box-shadow: var(--ads-v2-shadow-popovers) !important;
}

.error-menuitem {
  color: var(--ads-v2-color-fg-error) !important;
}
.error-menuitem > .ads-v2-menu__menu-item-children {
  color: var(--ads-v2-color-fg-error) !important;
}

.error-menuitem:hover:not([data-disabled]),
.error-menuitem:focus-visible {
  background-color: var(--ads-v2-color-red-50) !important;
}
.error-menuitem:active:not([data-disabled]) {
  background-color: var(--ads-v2-color-red-100) !important;
}
.menuitem-nohover:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.react-datepicker__children-container {
  margin-top: -10px !important;
}

.reconnect-datasource-modal {
  z-index: 9 !important;
}


.newInput{
  position: relative !important;
  background: #fff !important;
}
    
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container{
  position: absolute !important;
  top: calc(-0.875rem / 2 + 1px) !important;
  left: 10px !important;
  z-index: 20 !important;
  /* margin-top: 9px; */
  line-height: 0.875rem;
}
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignM{
  top: calc(-0.875rem / 2 + -2px) !important;
}
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignL{
  top: calc(-1.875rem / 2 + 1px) !important;
}
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignXL{
  top: calc(-2rem / 2 + -7px) !important
}
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignXXXL{
  top: calc(-3rem / 2 + 0px) !important;
  line-height: 3rem;
}


.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignM label{
  line-height: 1.25rem !important;
}
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignL label{
  line-height: 2rem !important;
}
.newInput .bp3-control-group.bp3-fill.sc-yLwuU .label-container.topAlignXL label{
  line-height: 3rem !important;
}

.newInput .bp3-popover-wrapper{
  background: #fff !important;
  padding: 0px 5px;
  display: inline-block;
  
}
.newInput .text-input-wrapper .bp3-popover-wrapper {
  width: 100%;
}
.dEdcRB, .fZJgbl, .dxbQAl, .ekugMu, .dvTfXa, .fSHTJV {
  align-items:flex-start !important;
}

.t--draggable-textwidget .text-input-wrapper {
  height: 17px !important;
}
.t--draggable-textwidget .sc-ljpbHA.giiEBH {
  min-height: 17px !important;
}
.cDPPUR.cDPPUR.cDPPUR {
  height: 100%;
}
.bp3-control-group.bp3-fill{
  height: 100%;
}

.cIKeaV, .cIKeaV .rc-tree-select, .fNWVsv, .fNWVsv .rc-tree-select, .haiLES, .caeaYM, .hzvSxi{
  height: 100% !important;
}
 
/* .container-with-scrollbar .positioned-widget.t--widget-containerwidget, .sc-ljpbHA.giiEBH, .resize-wrapper, .sc-hgRfpC {
width: 100% !important;
} */

.px-2.py-4.text-base .t--sign-up.t--signup-link {
  display: inline-flex !important;
}

.sc-gXnjX.hMlnC.t--draggable-tabswidget,.sc-gXnjX.hMlnC.t--draggable-containerwidget .resize-wrapper.show-boundary {
  height: initial !important;
}
.t--draggable-inputwidgetv2 {
  height: initial !important;
}

.ads-v2-button.t--new-button.createnew:hover, .ads-v2-button.t--application-edit-link:hover {
  background-color: #cf4d10;
}

.bp3-input {
  line-height: inherit;
}

.cLrsSw, .cLrsSw .rc-tree-select {
  height: inherit !important;
}

.eYetyQ > button, .kMKKSo {
  height: auto !important;
}
.bp3-button {
  min-height: 28px !important;
}
.t--buttongroup-widget .sc-hixjlP.eYetyQ > button {
  min-height: 28px !important;
}
.t--widget-buttongroupwidget .sc-hgRfpC.resize-wrapper.show-boundary {
  height: auto !important;
}
.bp3-button.bp3-large, .bp3-large .bp3-button {
  padding: 4px 15px;
}

.sc-cURfjK.gPFNSJ, .sc-hshgAP.gElCpz, .sc-eOdrAP.cFeQtX, .t--draggable-iconbuttonwidget .bp3-button.bp3-large, .t--draggable-multiselectwidgetv2 .AgVGS, .t--draggable-multiselecttreewidget .hurFyQ {
  height: auto !important;
}